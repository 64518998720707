.login-page {
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 40px 0;
  .brand {
    text-align: center;
    margin-bottom: 8px;
    margin-top: 8px;
    .logo {
      height: 160px;
      width: 160px;
      background-size: 150px 150px;
      top: -4px;
      background-image: url(/images/selfbPLUS.svg);
    }
    .name {
      font-size: 36px;
    }
  }
  .ant-input-affix-wrapper .ant-input-suffix {
    right: 22px;
  }
}
