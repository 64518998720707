.ant-layout-header {
  &.global-header {
    padding: 0 20px;
    display: flex;
    background: #ffffff;
    border-bottom: 1px solid #ccc;
    color: #000;
    height: 85px;
    -webkit-box-shadow: 2px 3px 7px -6px rgba(66, 65, 66, 1);
    -moz-box-shadow: 2px 3px 7px -6px rgba(66, 65, 66, 1);
    box-shadow: 2px 3px 7px -6px rgba(66, 65, 66, 1);
    .ant-menu-dark {
      background: #ffffff;
      .ant-menu-item {
        color: #fff;
      }
    }
    .ant-menu.ant-menu-dark .ant-menu-item-selected,
    .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
      background-color: #fff;
    }
  }
  .ant-menu-horizontal {
    border-bottom: 0 !important;
    float: right;
    text-align: right;
    margin-top: 10px;
  }
  .ant-menu-horizontal > .ant-menu-item > a {
    color: #89851f;
    font-weight: 300;
    font-size: 16px;
  }
  .ant-menu-horizontal > .ant-menu-item > a:hover {
    color: #cec725;
  }
  .ant-menu-horizontal > .ant-menu-item,
  .ant-menu-item.ant-menu-item-active {
    border-bottom-width: 0 !important;
  }
}
.profile-bg {
  background-color: #85812e;
  background: url(/images/profile-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 160px;
  text-align: center;
  position: relative;
  .profile-initial {
    width: 150px;
    height: 150px;
    border: 6px solid rgba(255, 255, 255, 0.7);
    border-radius: 50% !important;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -30px;
    z-index: 1;
    background: rgba(224, 223, 223, 0.7);
    line-height: 136px;
    font-size: 70px;
    font-weight: 100;
  }
}
.profile-name {
  text-align: center;
  font-size: 24px;
  font-weight: 100;
  margin-top: 45px;
  margin-bottom: 0;
}
